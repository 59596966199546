import React from 'react';
import { TableElementProps } from './types';

export const TableHeaderCell = ({
  children,
  title,
  className = '',
  style,
  onClick,
}: TableElementProps) => {
  return (
    <td style={style} title={title} onClick={onClick} className={`py-2 px-3 ${className}`}>
      {children}
    </td>
  );
};
