import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getCompanies, getESGRatingsByIdList } from '../../api/requests';
import { GeneralRating } from '../../api/types';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useLettersData } from '../../hooks/api/useLettersData';

interface RatingsESGTableProps {
  rating?: GeneralRating;
}

export const RatingsESGTable = ({ rating }: RatingsESGTableProps) => {
  const lettersData = useLettersData();
  const { generalRatings, isLoading } = useGeneralRatings();
  const { data: companies, isLoading: isCompaniesLoading } = useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
  });

  const filteredGeneralRatingIds = useMemo(() => {
    const idList = [];
    for (const generalRating of generalRatings || []) {
      if (generalRating.rating_name === rating?.rating_name) {
        idList.push(generalRating.esg_rating_tab_id || 0);
      }
    }
    return idList;
  }, [generalRatings, rating?.rating_name]);

  const { data: ratingsEsg, isLoading: isLoadingESG } = useQuery({
    queryKey: ['ratingsEsg', rating?.rating_name],
    queryFn: async () => await getESGRatingsByIdList(filteredGeneralRatingIds),
    enabled: !!filteredGeneralRatingIds,
  });

  const filteredCompanies = useMemo(
    () =>
      companies?.filter((_) =>
        ratingsEsg?.find((__) => __.year_rating_esg_tab?.company?.id === _.id),
      ),
    [companies, ratingsEsg],
  );

  const letterToCell = useCallback(
    (key?: string, letter?: string) => {
      if (!letter) {
        return {};
      }

      const letterData = lettersData?.find((_) => _.letter_esg === letter);

      return {
        cell: <div key={key}>{letter}</div>,
        sortValue: letterData?.rating_esg_index,
        style: { backgroundColor: letterData?.hex },
      };
    },
    [lettersData],
  );

  return (
    <div>
      {ratingsEsg?.length ? (
        <Table
          isSortable={true}
          cols={['Компания', 'Отрасль', 'Регион', 'E', 'S', 'G', 'ESG']}
          rows={ratingsEsg?.map(({ year_rating_esg_tab }, i) => {
            const company = filteredCompanies?.find(
              (_) => _.id === year_rating_esg_tab?.company?.id,
            );
            return [
              {
                cell: (
                  <Link
                    to={`/companies/${company?.id}`}
                    key={`${i}-name`}
                    className="text-main hover:underline"
                  >
                    {company?.company_name}
                  </Link>
                ),
                sortValue: company?.company_name?.toLowerCase(),
              },
              {
                cell: company?.industry?.industry_name || '-',
                sortValue: company?.industry?.industry_name,
              },
              {
                cell: '-',
              },
              letterToCell(`${company?.id}-e`, year_rating_esg_tab?.e_letter),
              letterToCell(`${company?.id}-s`, year_rating_esg_tab?.s_letter),
              letterToCell(`${company?.id}-g`, year_rating_esg_tab?.g_letter),
              letterToCell(`${company?.id}-esg`, year_rating_esg_tab?.esg_letter),
            ];
          })}
        />
      ) : null}
      {isLoadingESG || isLoading ? <Loader /> : null}
    </div>
  );
};
