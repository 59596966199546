import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getCompanies, getIndustries } from '../../api/requests';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';

export const Companies = () => {
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);

  const { data: companies, isLoading } = useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
  });
  const { data: industries } = useQuery({
    queryKey: ['industries'],
    queryFn: async () => {
      const data = await getIndustries();
      return data?.sort((a, b) => {
        if (!a.industry_name || !b.industry_name) {
          return 0;
        }

        if (a.industry_name > b.industry_name) {
          return 1;
        }
        if (a.industry_name < b.industry_name) {
          return -1;
        }
        return 0;
      });
    },
  });

  const filteredIndustries = useMemo(
    () =>
      industries?.map((_) => ({
        value: _.id,
        label: (_.industry_name || '') + ' - ' + (_.sub_industry_name || ''),
      })),
    [industries],
  );

  const filteredCompanies = useMemo(
    () =>
      selectedIndustries?.length
        ? companies?.filter((_) => selectedIndustries?.find((__) => __ === _.industry?.id))
        : companies,
    [selectedIndustries, companies],
  );

  return (
    <div>
      <Select
        options={filteredIndustries}
        className="mb-4"
        isMulti
        placeholder="Выбор отрасли"
        onChange={(e) => setSelectedIndustries(e.map((_) => _.value || 0))}
      />
      <Table
        isSortable={true}
        cols={['Название компании', 'ИНН', 'Отрасль', '', '', '']}
        rows={filteredCompanies?.map(({ id, company_name, inn, industry }, i) => [
          {
            cell: (
              <Link to={String(id)} key={i} className="text-main hover:underline">
                {company_name}
              </Link>
            ),
            sortValue: company_name?.toLowerCase(),
          },
          { cell: inn, sortValue: inn },
          { cell: industry?.industry_name, sortValue: industry?.industry_name?.toLowerCase() },
          {
            cell: (
              <Link to={`/reports/${String(id)}`} key={i} className="text-main hover:underline">
                Отчёты
              </Link>
            ),
          },
          {
            cell: (
              <Link to={`/news/${String(id)}`} key={i} className="text-main hover:underline">
                Новости
              </Link>
            ),
          },
          {
            cell: (
              <Link
                to={`/website/${String(id)}`}
                key={i}
                className="text-main hover:underline whitespace-nowrap"
              >
                Веб-сайт
              </Link>
            ),
          },
        ])}
      />
      {isLoading ? <Loader /> : null}
    </div>
  );
};
