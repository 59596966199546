import { useQuery } from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';
import { Chart, type ChartOptions } from 'react-charts';
import { useParams } from 'react-router-dom';
import { getESGRatingsByInn, getEthicsRatingsByInn, getReuterModels } from '../../api/requests';
import {
  GeneralRating,
  LetterData,
  RatingESG,
  RatingEthics,
  Reuter,
  ReuterModel,
} from '../../api/types';
import { Heading } from '../../components/Heading';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { type RowCellData, type TableProps } from '../../components/Table/Table';
import { useCompanyData } from '../../hooks/api/useCompanyData';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useLettersData } from '../../hooks/api/useLettersData';
import { useReuters } from '../../hooks/api/useReuters';

export const Company = () => {
  const { companyId } = useParams();

  const { companyData, industryData } = useCompanyData(companyId);
  const lettersData = useLettersData();
  const { reuters, isLoading: isReutersLoading } = useReuters();
  const { generalRatings } = useGeneralRatings();

  const { data: reuterModels, isLoading: isReuterModelsLoading } = useQuery({
    queryKey: ['reuterModels'],
    queryFn: getReuterModels,
  });

  const { data: ratingsEsg, isLoading: isESGLoading } = useQuery({
    queryKey: ['ratingsEsg', companyData?.inn],
    queryFn: async () => {
      const data = await getESGRatingsByInn(companyData?.inn || '');
      return data?.sort((a, b) => (a?.year || 0) - (b?.year || 0));
    },
    enabled: !!companyData?.inn,
  });

  const { data: ratingsEthics, isLoading: isEthicsLoading } = useQuery({
    queryKey: ['ratingsEthics', companyData?.inn],
    queryFn: async () => await getEthicsRatingsByInn(companyData?.inn || ''),
    enabled: !!companyData?.inn,
  });

  const tableData = useMemo(
    () =>
      buildTableData(
        ratingsEsg,
        reuters,
        reuterModels,
        lettersData,
        generalRatings?.filter((_) => ratingsEsg?.find((__) => __.id === _.esg_rating_tab_id)),
      ),
    [ratingsEsg, reuters, lettersData, generalRatings, reuterModels],
  );

  const reutersWithModels = getReutersWithModels(reuters, reuterModels);
  const ratingsESGWithModelId: RatingESGWithReuterModel[] = [];

  ratingsEsg?.forEach((rating) => {
    const generalRating = generalRatings?.find((_) => _.esg_rating_tab_id === rating.id);
    ratingsESGWithModelId.push({ ...rating, reuter_model_id: generalRating?.reuter_model_id });
  });

  const chartDataESG: ChartOptions<RatingESG> = useMemo(
    () => ({
      data:
        reutersWithModels?.map((_) => ({
          label:
            (_.reuter_name || '') +
            (_.model?.rating_model_name ? ' - ' + _.model?.rating_model_name : ''),
          data:
            ratingsESGWithModelId
              ?.sort((a, b) => (a.year || 0) - (b.year || 0))
              .filter(
                (__) =>
                  __.reuter_id === _.id && (_.model?.id ? _.model.id === __.reuter_model_id : true),
              ) || [],
        })) || [],
      primaryAxis: {
        getValue: (data) => data.year || 0,
        scaleType: 'band',
      },
      secondaryAxes: [
        {
          getValue: (data) =>
            lettersData?.find(
              (_) =>
                _.letter_esg === data.year_rating_esg_tab?.esg_letter ||
                _.letter_esg === data.year_rating_esg_tab?.range,
            )?.rating_esg_index,
          formatters: {
            scale: (data: number) =>
              lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || '',
            tooltip: (data: number) => (
              <div>{lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || ''}</div>
            ),
          },
          min: 0,
        },
      ],
    }),
    [reuters, ratingsEsg, lettersData],
  );

  const chartDataEthics: ChartOptions<RatingEthics> = useMemo(
    () => ({
      data:
        ratingsEthics?.map((_) => ({
          label: String(_.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
          data: ratingsEthics
            .sort((a, b) => (a?.id || 0) - (b?.id || 0))
            .map((_, i) => ({ ..._, id: i + 1 })),
        })) || [],
      primaryAxis: {
        getValue: (data) => data.id,
        scaleType: 'linear',
        formatters: {
          scale: () => '',
          tooltip: () => '',
        },
      },
      secondaryAxes: [
        {
          getValue: (data) => Number(data.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
        },
      ],
    }),
    [ratingsEthics],
  );

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name}</Heading>
      <div className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </div>
      <div className="mb-10">
        <Heading size="S">Рейтинги ESG</Heading>
        <Table isSortable={true} cols={tableData?.cols} rows={tableData?.rows} />
        {isESGLoading || isReutersLoading || isReuterModelsLoading ? <Loader /> : null}
        {!ratingsEsg?.length && !isESGLoading ? (
          <Heading className="italic font-normal text-center text-gray" size="XS">
            Данные рейтинга ESG отсутствуют
          </Heading>
        ) : null}
        {chartDataESG?.data?.length && ratingsEsg?.length ? (
          <div className="h-[200px] mt-10">
            <Chart options={chartDataESG} />
          </div>
        ) : null}
      </div>
      <div className="mb-10">
        <Heading size="S">Индекс этичности</Heading>
        <Table
          isSortable={true}
          cols={['Год', 'Индекс', 'Кол-во обзоров (негативных/нейтральных/позитивных)']}
          rows={ratingsEthics?.map((_, i) => [
            { cell: _.year, sortValue: _.year },
            {
              cell: _.year_rating_ethics_tab?.index_simple?.toFixed(4),
              sortValue: _.year_rating_ethics_tab?.index_simple,
            },
            {
              cell: (
                <div key={i}>
                  <span className="text-rating-c">
                    {_.year_rating_ethics_tab?.negative_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-ccc">
                    {_.year_rating_ethics_tab?.neutral_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-aaa">
                    {_.year_rating_ethics_tab?.positive_reviews_count || '-'}
                  </span>
                </div>
              ),
              sortValue:
                (_.year_rating_ethics_tab?.negative_reviews_count || 0) +
                (_.year_rating_ethics_tab?.neutral_reviews_count || 0) +
                (_.year_rating_ethics_tab?.positive_reviews_count || 0),
            },
          ])}
        />
        {isEthicsLoading ? <Loader /> : null}
        {!ratingsEthics?.length && !isEthicsLoading ? (
          <Heading className="italic font-normal text-center text-gray" size="XS">
            Данные индекса этичности отсутствуют
          </Heading>
        ) : null}
        {chartDataEthics?.data?.length && ratingsEthics?.length ? (
          <div className="h-[200px] mt-10">
            <Chart options={chartDataEthics} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface ReuterWithModel extends Reuter {
  model?: ReuterModel;
}

interface RatingESGWithReuterModel extends RatingESG {
  reuter_model_id?: number;
}

const getReutersWithModels = (
  reuters?: Reuter[],
  reuterModels?: ReuterModel[],
): ReuterWithModel[] => {
  const reutersWithModels: ReuterWithModel[] = [];
  reuters?.forEach((reuter) => {
    const models = reuterModels?.filter((_) => _.reuter_id === reuter.id);
    if (models?.length) {
      models.forEach((model) => reutersWithModels.push({ ...reuter, model }));
    }
    reutersWithModels.push({ ...reuter });
  });

  return reutersWithModels;
};

function buildTableData(
  ratingsEsg?: RatingESG[],
  reuters?: Reuter[],
  reuterModels?: ReuterModel[],
  lettersData?: LetterData[],
  generalRatings?: GeneralRating[],
) {
  const yearsSet = new Set<number>();
  const cols: (string | ReactNode)[] = ['Год'];
  const rows: TableProps['rows'] = [];

  const ratingsESGWithModelId: RatingESGWithReuterModel[] = [];

  ratingsEsg?.forEach((rating) => {
    if (rating.year) {
      yearsSet.add(rating.year);
    }
    const generalRating = generalRatings?.find((_) => _.esg_rating_tab_id === rating.id);
    ratingsESGWithModelId.push({ ...rating, reuter_model_id: generalRating?.reuter_model_id });
  });

  const reutersWithModels = getReutersWithModels(reuters, reuterModels);

  reutersWithModels?.forEach((reuter) => {
    cols.push(
      <div className="flex flex-col">
        <div>{reuter.reuter_name}</div>
        {reuter.model?.rating_model_name ? <div>{reuter.model?.rating_model_name}</div> : null}
      </div>,
    );
  });

  const years = Array.from(yearsSet).sort();
  const processedRatings: number[] = [];

  years.forEach((year) => {
    const row: RowCellData[] = [{ cell: year, sortValue: year }];
    reutersWithModels?.forEach((reuter) => {
      const rating = ratingsESGWithModelId?.find((rating) => {
        return (
          rating.year === year &&
          !processedRatings.find((_) => _ === rating.id) &&
          (reuter?.model
            ? rating.reuter_model_id === reuter?.model?.id
            : rating.reuter_id === reuter.id)
        );
      });

      if (rating) {
        processedRatings.push(rating.id || 0);
      }

      const letter = rating?.year_rating_esg_tab?.esg_letter || rating?.year_rating_esg_tab?.range;
      const letterData = lettersData?.find((_) => _.letter_esg === letter);

      row.push({
        cell: letter,
        sortValue: letterData?.rating_esg_index,
        style: { backgroundColor: letterData?.hex },
      });
    });

    rows.push(row);
  });

  return { cols, rows };
}
