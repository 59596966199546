import { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { MdArrowUpward } from 'react-icons/md';
import { Heading } from '../Heading';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import { TableHeaderCell } from './TableHeaderCell';
import { TableRow } from './TableRow';

export type RowCellData = {
  cell?: ReactNode;
  sortValue?: string | number;
  className?: string;
  style?: CSSProperties;
};

export type TableProps = JSX.IntrinsicElements['table'] & {
  title?: string;
  cols?: (string | ReactNode)[];
  rows?: RowCellData[][];
  alignTop?: boolean;
  indexColAlign?: 'left' | 'center' | 'right';
  isSortable?: boolean;
};

export function Table({
  title,
  cols,
  rows,
  className = '',
  indexColAlign = 'center',
  alignTop = false,
  isSortable = false,
  ...rest
}: TableProps) {
  const [sortConfig, setSortConfig] = useState<{ index: number; dir: 'asc' | 'desc' } | null>(null);

  const sortedRows = useMemo(() => {
    if (!!sortConfig) {
      return rows?.sort((a, b) => {
        if ((a[sortConfig?.index]?.sortValue || '') < (b[sortConfig?.index]?.sortValue || '')) {
          return sortConfig?.dir === 'asc' ? -1 : 1;
        }
        if ((a[sortConfig?.index]?.sortValue || '') > (b[sortConfig?.index]?.sortValue || '')) {
          return sortConfig?.dir === 'asc' ? 1 : -1;
        }

        return 0;
      });
    }

    return rows;
  }, [rows, sortConfig]);

  return (
    <div className={className}>
      {title && <Heading size="S">{title}</Heading>}
      <table
        className="border-t border-darkGray border-opacity-20 w-full whitespace-pre-wrap"
        {...rest}
      >
        <TableHeader>
          <TableRow>
            {cols?.map((_, i) => (
              <TableHeaderCell
                key={i}
                className={
                  (alignTop ? 'align-top ' : '') +
                  `${_ === '' && 'w-[48px]'} ` +
                  getCellClassNameByIndex(i, cols.length) +
                  (i === 0 ? ` text-${indexColAlign}` : '') +
                  ' overflow-ellipsis'
                }
              >
                {isSortable ? (
                  <button
                    className="flex items-center gap-2"
                    onClick={() =>
                      setSortConfig({
                        index: i,
                        dir:
                          !!sortConfig && sortConfig?.index === i
                            ? sortConfig?.dir === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'desc',
                      })
                    }
                  >
                    <span>{_}</span>
                    {_ === '' ? null : (
                      <MdArrowUpward
                        className={
                          sortConfig?.index === i && sortConfig?.dir === 'desc' ? 'rotate-180' : ''
                        }
                      />
                    )}
                  </button>
                ) : (
                  _
                )}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedRows?.map((_, i) => (
            <TableRow key={i}>
              {_?.map(({ cell, className, style }, i) => (
                <TableCell
                  key={i}
                  style={style}
                  className={
                    (alignTop ? 'align-top ' : '') +
                    getCellClassNameByIndex(i, _.length) +
                    className
                  }
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </table>
    </div>
  );
}

const getCellClassNameByIndex = (i: number, length: number) => {
  return i === length - 1 ? ' ' : 'border-r border-darkGray border-opacity-20 ';
};
